@use "../../../styles/mixins" as *;
@include layer(ui) {
    .outer-container {
        display: flex;
        flex-direction: column;
    }

    .slider-container {
        position: relative;
        display: flex;
        align-items: center;
        min-width: 100%;
    }

    .skip {
        &--visually-hidden:not(:focus-visible) {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }

        align-self: center;
        margin: var(--g-spacing-md) 0;
        scroll-margin-top: var(--g-spacing-xl);
    }

    .button {
        position: absolute;
        z-index: 2;

        &--left {
            top: calc(50% - (var(--c-slider-button-standard-height) / 2));
            left: calc(var(--g-spacing-md) * -1);
        }

        &--right {
            top: calc(50% - (var(--c-slider-button-standard-height) / 2));
            right: calc(var(--g-spacing-md) * -1);
        }
    }
}
