@use "../../../styles/mixins" as *;
@include layer(ui) {
    .slide {
        display: flex;
        > * {
            flex: 1;
            max-width: 100%;
        }

        &:focus-visible {
            outline: var(--g-border-width-sm) solid
                var(--s-color-brand-primary-standard);
            outline-offset: var(--g-spacing-xxs);
        }
    }
}
