@use "../../../styles/mixins" as *;
@include layer(ui) {
    .track-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        width: 100%;
        padding: var(--g-spacing-lg);
        margin: calc(var(--g-spacing-lg) * -1);
    }

    .track {
        display: grid;
        min-width: 100%;
        max-width: 100%;
        gap: var(--item-gap);
        grid-template-columns: repeat(var(--nb-items), var(--mobile-width));

        @include breakpoint-md {
            grid-template-columns: repeat(var(--nb-items), var(--tablet-width));
        }

        @include breakpoint-lg {
            grid-template-columns: repeat(
                var(--nb-items),
                var(--desktop-width)
            );
        }
    }
}
