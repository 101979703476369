@use "../../../styles/mixins" as *;
@include layer(ui) {
    .container {
        font-size: var(--g-size-xxs);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &--standard {
            height: var(--c-slider-button-standard-height);
            width: var(--c-slider-button-standard-height);
            border-radius: var(--c-slider-button-standard-border-radius);
            border: var(--g-border-width-xs) solid transparent;
            background-color: var(--background-default);
            box-shadow: (var(--c-slider-button-standard-shadow-default));
            font-size: var(--c-slider-button-standard-icon-size);
            color: var(--icon-color-default);
            transition: none var(--g-duration-xshort) ease;
            transition-property: background-color, color, filter;
            cursor: pointer;
            animation: pop-in var(--g-duration-xshort) ease;
            stroke-width: var(--c-slider-button-standard-icon-stroke-width);

            @media (forced-colors: active) {
                border: none;
                outline: var(--g-border-width-sm) solid;

                &:focus-visible {
                    border: var(--g-border-width-xs) solid transparent;
                }
            }

            @keyframes pop-in {
                from {
                    transform: scale(0);
                }
                to {
                    transform: scale(1);
                }
            }

            &--visually-hidden:not(:focus-visible) {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                white-space: nowrap;
                width: 1px;
            }

            &:hover:not([aria-disabled="true"]) {
                background-color: var(--background-active);
                box-shadow: (var(--c-slider-button-standard-shadow-active));
                color: var(--icon-color-active);
            }

            &:focus-visible {
                transition: none;
                outline: solid var(--g-border-width-sm)
                    var(--outline-color-focus);
                outline-offset: var(
                    --c-slider-button-standard-outline-offset-focus
                );

                &:not([aria-disabled="true"]) {
                    background-color: var(--background-focus);
                    box-shadow: (var(--c-slider-button-standard-shadow-active));
                    color: var(--icon-color-focus);
                }
            }

            &:active:not([aria-disabled="true"]) {
                transform: scale(0.96);
            }

            &[aria-disabled="true"] {
                cursor: not-allowed;
                background-color: var(--background-disabled);
                color: var(--icon-color-disabled);
                filter: none;
            }
        }

        &--quiet {
            height: var(--c-slider-button-quiet-height);
            width: var(--c-slider-button-quiet-height);
            border-radius: var(--c-slider-button-quiet-border-radius);
            border: var(--g-border-width-xs) solid transparent;
            background-color: var(--background-default);
            font-size: var(--c-slider-button-quiet-icon-size);
            color: var(--icon-color-default);
            transition: none var(--g-duration-xshort) ease;
            transition-property: background-color, color;
            cursor: pointer;
            stroke-width: var(--c-slider-button-quiet-icon-stroke-width);

            &:hover:not([aria-disabled="true"]) {
                background-color: var(--background-active);
                color: var(--icon-color-active);
            }

            &:focus-visible {
                transition: none;
                outline: solid var(--g-border-width-sm)
                    var(--outline-color-focus);

                &:not([aria-disabled="true"]) {
                    background-color: var(--background-focus);
                    color: var(--icon-color-focus);
                }
            }

            &:active:not([aria-disabled="true"]) {
                transform: scale(0.96);
            }

            &[aria-disabled="true"] {
                cursor: not-allowed;
                background-color: var(--background-disabled);
                color: var(--icon-color-disabled);
            }
        }

        &--quiet-inverse {
            height: var(--c-slider-button-quiet-inverse-height);
            width: var(--c-slider-button-quiet-inverse-height);
            border-radius: var(--c-slider-button-quiet-inverse-border-radius);
            border: var(--g-border-width-xs) solid transparent;
            font-size: var(--c-slider-button-quiet-inverse-icon-size);
            color: var(--icon-color-default);
            transition: none var(--g-duration-xshort) ease;
            transition-property: color;
            cursor: pointer;
            background: transparent;
            stroke-width: var(
                --c-slider-button-quiet-inverse-icon-stroke-width
            );

            &:hover:not([aria-disabled="true"]) {
                color: var(--icon-color-active);
            }

            &:focus-visible {
                transition: none;
                outline: solid var(--g-border-width-sm)
                    var(--outline-color-focus);

                &:not([aria-disabled="true"]) {
                    color: var(--icon-color-focus);
                }
            }

            &:active:not([aria-disabled="true"]) {
                transform: scale(0.96);
            }

            &[aria-disabled="true"] {
                cursor: not-allowed;
                color: var(--icon-color-disabled);
                opacity: var(--g-opacity-quiet);
            }
        }
    }
}
